<template>
  <div class="text-center" id="diag_text_root">
    <v-dialog id="mapdiag" v-model="map_dialog_visible" width="768">
      <v-card height="768">
        <v-card-title class="text-h5 grey lighten-2">
          MAP of Reno
        </v-card-title>

        <v-card-text> Please click on the location of the stop. </v-card-text>

        <v-divider></v-divider>
        <div id="map-canvas" style="width: 750px; height: 600px"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="recenter">Recenter</v-btn>
          <v-btn v-if="edit_on" color="primary" @click="remove_all_markers">
            Clear
          </v-btn>
          <v-btn color="primary" @click="close_mapdiag"> Done </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
.vdialog,
.v-dialog {
  max-width: 768px;
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "mapdiag",
  mixins: [bus_common],
  props: ["map_dialog_visible", "stat_data", "edit_on"],
  data() {
    return {
      e: null,
      address: "",
      current_geo_postion: { lat: 0, lng: 0 },
      lat: 0,
      lng: 0,
      map: null, // Global declaration of the map
      iw: null,
      marker: null,
      lat_longs: [],
      markers: [],
      drawingManager: null,
    };
  },
  watch: {
    map_dialog_visible: function () {
      var self = this;
      if (this.map_dialog_visible == true) {
        this.$nextTick(() => {
          self.init();
        });
        // setTimeout(function () {
        //   self.init();
        // }, 3000);
      }
    },
  },
  methods: {
    recenter() {
      this.mrbreakpoint("brk");
      if (this.stat_data.lat == 0 && this.current_geo_postion.lat == 0) {
        // var poslatlng = this.geolocate();
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.current_geo_postion = pos;
          var myLatlng = new google.maps.LatLng(pos.lat, pos.lon);
          var zoom = 15;
          this.map.setCenter(pos);
          this.map.setZoom(zoom);
          this.geocode(pos);
        });
      } else {
        const pos = {
          lat: this.current_geo_postion.lat,
          lng: this.current_geo_postion.lng,
        };
        var zoom = 15;
        this.map.setCenter(pos);
        this.map.setZoom(zoom);
        this.geocode(pos);
      }
    },
    geocode(myLatlng) {
      const geocoder = new google.maps.Geocoder();
      const infowindow = new google.maps.InfoWindow();
      const latlng = {
        lat: parseFloat(myLatlng.lat),
        lng: parseFloat(myLatlng.lng),
      };
      geocoder
        .geocode({ location: latlng })
        .then((response) => {
          if (response.results[0]) {
            this.address = response.results[0].formatted_address;
            //this.map.setZoom(11);
            // alert(response.results[0].formatted_address);
            // const marker = new google.maps.Marker({
            //   position: latlng,
            //   map: this.map,
            // });

            // infowindow.setContent(response.results[0].formatted_address);
            // infowindow.open(map, marker);
          } else {
            this.address = "";
            //window.alert("No results found");
          }
        })
        // .catch((e) => window.alert("Geocoder failed due to: " + e));
        .catch((e) => (this.e = e));
    },

    close_mapdiag() {
      var loc = {
        lat: this.lat,
        lng: this.lng,
      };
      this.$emit("close_mapdiag", loc, this.address);
    },
    placeMarker(map, location) {
      if (this.marker != null) {
        this.marker.setMap(null);
        this.marker = null;
      }

      this.marker = new google.maps.Marker({
        position: location,
        map: map,
      });
      var infowindow = new google.maps.InfoWindow({
        content:
          "Latitude: " + location.lat() + "<br>Longitude: " + location.lng(),
      });
      infowindow.open(this.map, this.marker);
      this.lat = location.lat();
      this.lng = location.lng();

      const pos = {
        lat: this.lat,
        lng: this.lng,
      };
      this.current_geo_postion = pos;
      this.geocode(pos);
    },
    remove_all_markers() {
      map_initialize();
      this.lat = 0;
      this.lng = 0;
    },
    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      console.log("location error");
    },

    map_initialize() {
      var self = this;
      var bExist = false;
      var zoom = 12.5;
      let myLatlng = new google.maps.LatLng(39.5238581, -119.8515168); //at: 39.5238581, lng: -119.8515168
      if (this.stat_data.lat != 0) {
        myLatlng = new google.maps.LatLng(
          this.stat_data.lat,
          this.stat_data.lon
        );
        bExist = true;
      }

      let myOptions = {
        zoom: zoom,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
      };
      this.map = new google.maps.Map(
        document.getElementById("map-canvas"),
        myOptions
      );
      if (bExist) {
        var mrkhere = new google.maps.LatLng(
          this.stat_data.lat,
          this.stat_data.lon
        );
        //capture for saving
        this.lat = this.stat_data.lat;
        this.lng = this.stat_data.lon;

        this.marker = new google.maps.Marker({ position: mrkhere });

        this.marker.setMap(this.map);
      }
      google.maps.event.addListener(this.map, "click", function (event) {
        self.placeMarker(self.map, event.latLng);
      });
      // move to device postion
      if (this.stat_data.lat == 0) {
        // var poslatlng = this.geolocate();
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.current_geo_postion = pos;
          var myLatlng = new google.maps.LatLng(pos.lat, pos.lon);
          var zoom = 15;
          this.map.setCenter(pos);
          this.map.setZoom(zoom);
          this.geocode(pos);
        });
      }

      // this.Map.setMap();
      //   this.drawingManager = new google.maps.drawing.DrawingManager({
      //     drawingMode: google.maps.drawing.OverlayType.POLYGON,
      //     drawingControl: true,
      //     drawingControlOptions: {
      //       position: google.maps.ControlPosition.TOP_CENTER,
      //       drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      //     },
      //     polygonOptions: {
      //       editable: true,
      //     },
      //   });
      //   this.drawingManager.setMap(this.map);

      //   google.maps.event.addListener(
      //     this.drawingManager,
      //     "overlaycomplete",
      //     (event) => {
      //       var newShape = event.overlay;
      //       newShape.type = event.type;
      //       this.polys.push(newShape);
      //     }
      //   );

      //   google.maps.event.addListener(
      //     this.drawingManager,
      //     "overlaycomplete",
      //     (event) => {
      //       this.overlayClickListener(event.overlay);
      //       this.vertices_value = event.overlay.getPath().getArray();
      //     }
      //   );
    },
    // overlayClickListener(overlay) {
    //   google.maps.event.addListener(overlay, "mouseup", (event) => {});
    // },
    init() {
      this.show_loader(false);

      var cur_size = window.matchMedia("(max-width: 600px)");
      if (cur_size.matches) {
        var width_hc = document.getElementById("home-container").offsetWidth;
        var height_hc = document.getElementsByTagName("BODY")[0].offsetHeight;
        document.getElementById("map-canvas").style.width =
          width_hc * 0.85 + "px";
        document.getElementById("map-canvas").style.height =
          height_hc * 0.65 + "px";
        document.querySelector(".v-card").style.height = height_hc * 1 + "px";

        let isIOS =
          /iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

        if (isIOS) {
          document.getElementById("map-canvas").style.height =
            height_hc * 0.62 + "px";
          document.querySelector(".v-card").style.height = height_hc * 1 + "px";
          console.log("ios: " + height_hc * 0.62 + "px");
        }
      }

      this.iw = new google.maps.InfoWindow(); // Global declaration of the infowindow
      this.map_initialize();
    },
  },
  mounted() {
    this.$nextTick(() => {
      //   this.init();
    });
  },
};
</script>
